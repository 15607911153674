import React, {useState} from "react"
// Structural Imports
import Layout from "@components/structure/Layout.jsx"
// SEO
import Seo from "@components/utility/Seo.jsx"

import SitemapContent from "@components/structure/SitemapContent"

const Sitemap = () => {

    return (
        <Layout>
            <Seo
            canonical="/sitemap"
            title="Sitemap | XYREM®"
            description="Review the Xyrem.com sitemap. See XYREM® (sodium oxybate) prescribing information & BOXED Warning about serious side effects, CNS depression, misuse and abuse."
            />
            <section className='container-md section-container pageLinks section section--first'>
                <h1 className="blueHeading text-center">Site map</h1>
                <SitemapContent />
            </section>
        </Layout>
    )
}

export default Sitemap
