import React from 'react'
import { Link } from "gatsby"

const SitemapContent = () => {
    return (
        <>
            <Link className='blueSubheading block text-left' to='/'>Homepage</Link>
            <Link className='blueSubheading block text-left' to='/support-and-resources/'>Support & Resources</Link>
            <Link className='blueSubheading block text-left' to='/stay-connected/'>Stay Connected</Link>
        </>
    )
}

export default SitemapContent
